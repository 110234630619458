<template>
  <v-text-field
  v-model="inputVal"
    :type="type"
    :label="lable"
    key="name"
    :rules="[() => !!inputVal || lable+ ' is required']"
    min="1"
  ></v-text-field>
</template>

<script>
export default {
   props: ["value","lable","type"],
    data: () => ({
    name: null,
  }),
   computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  mounted(){
  }
};
</script>

<style>
</style>