<template>
  <v-select
    label="Sponsor"
    class="white"
    :items="data"
    :item-text="events"
    item-value="id"
    v-model="inputVal"
    :loading="api.isLoading"
    placeholder="Event"
    :rules="rules"
    :disabled="action == 'edit'"
  ></v-select>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["callbackSelect", "value", "action", "callbackError"],
  data: () => ({
    rules: [(v) => !!v || "Sponsor is required"],
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    id: null,
    item: null,
  }),
  computed: {
    ...mapState({
      school: (state) => state.school.data,
    }),
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  created() {
    //BOC:[api]
    this.api.method = "get";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
      this.item = resp[0];
      if (this.action == "add") {
        this.inputVal = resp[0].id;
      } else {
        this.id = resp[0].id;
      }
    };
    //EOC
  },
  methods: {
    fetch() {
      if (this.action == "add") {
        this.api.url =
          this.$api.servers.backend +
          "/v1/" +
          this.$_getLocale() +
          "/data/sponsor?schoolId=" +
          this.school.id +
          "&festivalId=" +
          this.$route.params.parentId;
      } else {
        this.api.url =
          this.$api.servers.backend +
          "/v1/" +
          this.$_getLocale() +
          "/data/sponsor?schoolId=" +
          this.school.id;
      }
      this.api.params = this.$_.clone({
        festivalId: this.$route.params.parentId,
      });
      this.$api.fetch(this.api);
    },
    events: (item) => item.name,
  },
  beforeMount() {
    this.fetch();
  },
};
</script>
